<template>
  <div class="home">
    <div class="top_banner ">
      <div class="top_banner_img">
        <div class="btn_group l_flex">
          <img src="@/assets/btn1.png" class="img_btn" @click="btn1ck" />
          <img src="@/assets/btn2.png" class="img_btn" @click="btn2ck" />
        </div>
      </div>
    </div>
     <!-- 核心优势 -->
     <div class="l_flex main_advantage ">
      <div class="l_flex advantage_top ">
        <img
          src="@/assets/first_pic01.png"
          class="first_image"/>
        <span class="black_title">核心优势</span>
      </div>
      <div class="gray_desc ">
        Core Advantages
      </div>
    </div>
    <div class="advantage ">
      <div class=" l_flex advantage_main ">
        <div class="advantage_body ">
          <div class="avt_left ">
            <div class="avt_left_obj ">
              <div 
                v-for="(item, index) in avtCardArr" 
                :key="index" 
                class="avt_left_card"
                @click="avtCardClick(item,index)"
                >
                <template v-if="index == currentAvtIndex">
                  <img src="@/assets/advantage_btn_active.png" style="height: 102px; width: 423px" class="avt_left_card_img"/>
                  <div class="l_flex avt_left_card_text_obj">
                    <div class="avt_left_card_text_item fst_text"> {{ item.title }} </div>
                    <div class="avt_left_card_text_item sec_text"> {{ item.desc }} </div>
                  </div>
                </template>
                <template v-else>
                  <img src="@/assets/advantage_btn_disActive.png" 
                    style="height: 93px; width: 390px;" 
                    class="avt_left_card_disActive_img"
                    />
                    <div class="l_flex avt_left_card_text_obj">
                    <div class="avt_left_card_text_item disActive_text"> {{ item.title }} </div>
                  </div>
                </template>
                
              </div>
            </div>
          </div>
          <div class="avt_center ">
            <div class=" avt_center_container ">
              <img :src="avtCenterImgCpt" style="height: 472px; width: 708px" class="avt_left_card_img"/>
            </div>
          </div>
          <div class="avt_right ">
            <div class="l_flex _column avt_r_container ">
              <div class="l_flex  avt_r_title_obj" v-for="(item, index) in avtTitleArr" :key="index" @click="avtTitleCk(item,index)">
                <div class="circle" style="margin-right: 5px;" />
                <div class="">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

     <!-- 互联网考级看流程 -->
     <div class="l_flex main_advantage internect">
      <div class="l_flex advantage_top ">
        <img
          src="@/assets/first_pic01.png"
          class="first_image"/>
        <span class="black_title">互联网考级流程</span>
      </div>
      <div class="gray_desc ">
        Internet Grading Process
      </div>
    </div>
    <div class="l_flex internect_pic_body ">

    </div>


    <!-- 广东音协考级App下载 -->
    <div class="l_flex main_advantage download_app">
      <div class="l_flex advantage_top ">
        <img
          src="@/assets/first_pic01.png"
          class="first_image"/>
        <span class="black_title">广东音协考级App下载</span>
      </div>
      <div class="gray_desc ">
        App Download
      </div>
    </div>
    <div class="l_flex download_app_pic ">
      <div class="download_app_pic_img">

      </div>
    </div>

     <!-- 广东音乐家协会互联网考级资讯 -->
     <div class="l_flex main_advantage music_info">
      <div class="l_flex advantage_top ">
        <img
          src="@/assets/first_pic01.png"
          class="first_image"/>
        <span class="black_title">广东音乐家协会互联网考级资讯</span>
      </div>
      <div class="gray_desc ">
        Internet Music Grading Information
      </div>
    </div>
    <div class="carousel">
      <div class=" l_flex carousel_main ">
        <div class="carousel_body ">
          <div class="carousel_bg_group">
            <div class="block" style="cursor: pointer;">
              <el-carousel height="473px" indicator-position="outside">
                <el-carousel-item v-for="(item, index) in carouselPicArr" :key="index">
                  <img :src="item.src" style="height: 473px; width: 839px" @click="carouselCk(index+1)"/>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
          <div class=" l_flex _column  article_r">
            <div class=" l_flex _row article_item fst_article_item" @click="carouselCk(1)">
              <div class="circle"></div>
              <span class="article_text">《音乐基础知识考级教程》公益讲座在潮汕地区举办</span>
            </div>
            <div class="l_flex _row  article_item" @click="carouselCk(2)">
              <div class="circle"></div>
              <span class="article_text">《音乐基础知识考级教程》公益讲座佛山站圆满结束</span>
            </div>
            <div class="l_flex _row article_item" @click="carouselCk(3)">
              <div class="circle"></div>
              <span class="article_text">2022年度广东省音协考级优秀考生展演在星海音乐厅举办</span>
            </div>
            <div class="l_flex _row article_item" @click="carouselCk(4)">
              <div class="circle"></div>
              <span class="article_text">关注"广东音基考级" 第一时间收到成绩 查询证书物流！</span>
            </div>
            <div class="l_flex _row article_item" @click="carouselCk(5)">
              <div class="circle"></div>
              <span class="article_text">广东省音乐家协会互联网考级操作指引（2022版）</span>
            </div>
            <div class="l_flex _row article_item" @click="carouselCk(6)">
              <div class="circle"></div>
              <span class="article_text">广东省新兴音乐教育发展大会：省音协考级专家讲座</span>
            </div>
          </div>
        </div>
      </div>
    </div>

     <!-- 广东音乐家协会互联网考级用户评价 -->
     <div class="l_flex main_advantage ">
      <div class="l_flex advantage_top ">
        <img
          src="@/assets/first_pic01.png"
          class="first_image"/>
        <span class="black_title">广东音乐家协会互联网考级用户评价</span>
      </div>
      <div class="gray_desc ">
        User Reviews Of Internet-based Grading.
      </div>
    </div>
    <div class="video">
      <div class=" l_flex video_main">
        <video class="video_main" controls>
          <source src="@/assets/welcome.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>

    <!-- 联系我们 -->
    <div class="l_flex foot_banner ">

    </div>



  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "HomeView",
  components: {
    // HelloWorld
  },
  computed: {
    avtCenterImgCpt() {
      let trueIndex = this.currentAvtIndex + 1;
      return require(`@/assets/avt_img_0${trueIndex}.png`)
    },
  },
  data: () => {
    return {
      carouselPicArr: [],
      avtCardArr: [],
      currentAvtIndex: 0,
      avtTitleArr: [],
    }
  },
  created() {
    this.setCarouselPicArr();
    this.setAvtCardArr();
    this.setAvtTitleArr();
  },
  methods: {
    setAvtTitleArr() {
      this.avtTitleArr = [
        { title: '每月举办一次', desc: '', index: 0, },
        { title: '自主安排时间', desc: '', index: 1, },
        { title: '手机报名缴费', desc: '', index: 2, },
        { title: '随时随地完成', desc: '', index: 3, },
      ]
    },
    avtTitleCk(item,index) {
      console.log(item,index)
    },
    avtCardClick(item, index) {
      console.log(item, index)
      this.setCurrentIndex(index);
      
      switch(index) {
        case 0 :
          this.avtTitleArr = [
            { title: '每月举办一次', desc: '', index: 0, },
            { title: '自主安排时间', desc: '', index: 1, },
            { title: '手机报名缴费', desc: '', index: 2, },
            { title: '随时随地完成', desc: '', index: 3, },
          ] 
        break;
        case 1:
          this.avtTitleArr = [
              { title: '由熟悉的老师录制', desc: '', index: 0, },
              { title: '用熟悉的乐器演奏', desc: '', index: 1, },
              { title: '在熟悉的环境考级', desc: '', index: 2, },
              { title: '让孩子更好地发挥', desc: '', index: 3, },
          ] 
        break;
        case 2:
          this.avtTitleArr = [
                { title: '人脸识别保障考级公正', desc: '', index: 0, },
                { title: '规范流程保障考级权威', desc: '', index: 1, },
                { title: '考级要求与线下一致', desc: '', index: 2, },
                { title: '考级证书与线下一致', desc: '', index: 3, },
            ]
        break;
        case 3:
          this.avtTitleArr = [
                  { title: '在线制作电子证件照', desc: '', index: 0, },
                  { title: '证书隔月制作并发出', desc: '', index: 1, },
                  { title: '中国邮政EMS快递到家', desc: '', index: 2, },
                  { title: '物流状态在线查询', desc: '', index: 3, },
              ]
        break;
      }
    },
    setCurrentIndex(index) {
      this.currentAvtIndex = index;
    },
    setAvtCardArr() {
      this.avtCardArr = [
        { title: '突破时空限制', desc: '根据学习进度灵活安排考级', index: 0, },
        { title: '减少现场干扰', desc: '在熟悉的环境录制考级视频', index: 1, },
        { title: '考级权威保障', desc: '规范流程确保考级公正严谨', index: 2, },
        { title: '证书快递到家', desc: '证书制作快EMS快递到家', index: 3, },
      ]
    },
    setCarouselPicArr() {
      this.carouselPicArr = [
        { src: require('@/assets/carousel_pic_01.png') },
        { src: require('@/assets/carousel_pic_02.png') },
        { src: require('@/assets/carousel_pic_03.png') },
        { src: require('@/assets/carousel_pic_04.png') },
        { src: require('@/assets/carousel_pic_05.png') },
        { src: require('@/assets/carousel_pic_06.png') },
      ]
    },
    btn1ck() {
      window.open("https://gschweb.yinjizaixian.cn/index.html#/login");
    },
    btn2ck() {
      window.open("https://gschweb.yinjizaixian.cn/index.html#/examPointApply");
    },
    carouselCk(index) {
      // console.log("index的值", index)
      switch (index) {
        case 1:
          let url1 = "https://mp.weixin.qq.com/s?__biz=MzkxMDIwMTY3Mw==&mid=2247483970&idx=1&sn=77e7b58a8bd46c44cfb38d3ab9822bce&chksm=c12e4464f659cd728eaa086fd7207a4d7857f4757a843b975c611f9dfae8a2a31f15839ae23e#rd";
          window.open(url1);
          break;
        case 2:
          let url2 = "https://mp.weixin.qq.com/s?__biz=MzI2MDA2NTA5Nw==&mid=2247484196&idx=1&sn=557b081d782d514f5beefcfeb453b3b5&chksm=ea6e1102dd1998146fd7e848a1991d166664e4633ea40ce9e3be7902b8007dbd45894582800d&token=569581368&lang=zh_CN#rd";
          window.open(url2);
          break;
        case 3:
          let url3 = "https://mp.weixin.qq.com/s/9VfwbKFtumgdpsi55izOqg";
          window.open(url3);
          break;
        case 4:
          let url4 = "https://mp.weixin.qq.com/s?__biz=MzkxMDIwMTY3Mw==&mid=2247483934&idx=1&sn=e03bcc87b4680fbc0f41c6a9ccc10693&chksm=c12e4438f659cd2e6546a0682834e41ee7f980b56abf14a56d19a954c3c9292f7051049eb154&token=277909141&lang=zh_CN#rd";
          window.open(url4);
          break;
        case 5:
          let url5 = "https://mp.weixin.qq.com/s?__biz=MzkxMDIwMTY3Mw==&mid=2247483799&idx=1&sn=b56b1856b6247ca2366e0f13db3db0f9&chksm=c12e47b1f659cea7558cdc2a536bc23843a337a68d3b14ed5fa83bfbb5096843cac7ac16cbbd&token=277909141&lang=zh_CN#rd";
          window.open(url5);
          break;
        case 6:
          let url6 = "https://mp.weixin.qq.com/s?__biz=MzI2MDA2NTA5Nw==&mid=2247483946&idx=1&sn=4772ad9533b0c96cf20df8a288ae823f&chksm=ea6e100cdd19991a5a2797318acfa3d4dc59c833a4cf15a7194b3da87c88db7ab7379aeb3618&token=569581368&lang=zh_CN#rd";
          window.open(url6);
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.noneClass{
  z-index: 0;
}

.black_title {
  color: #1c1c1c;
  font-family: Alibaba PuHuiTi;
  font-size: 40px;
  font-weight: 400;
  margin-left: 20px;
}
.gray_desc {
  color: #c6ced2;
  font-family: Alibaba PuHuiTi;
  font-size: 32px;
  font-weight: 300;
}
.br {
  border: 2px solid red;
}

::v-deep .el-carousel__indicators--outside button {
  background: white;
}

.l_flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.circle {
  height: 10px;
  width: 10px;
  background: white;
  border-radius: 50px;
}

._row {
  flex-direction: row;
}

._column {
  flex-direction: column;
}
html {
  font-size: 16px; /* 设置根元素的字体大小为16px */
}
.first_image{
  height: 40px;
  width: 60px;
}
.home {
  $defaultW: 1920px;
  position: relative;
  .top_banner{
    // height: 35vw;
    height: 670px;
    width: 100%;
    margin: 0 auto;
    // background-image: url("@/assets/top_banner.jpg");
    // background-size: 100% 100%; // 第二个参数根据第一个参数动态计算
    // background-repeat: no-repeat;
    // background-position: cover;
    // min-width: 1000px;
    min-width: 1024px;
    // min-height: 672px;
    // background-color: #FFD6B8;
    .top_banner_img{
      height: 670px;
      // width: 1900px;
      max-width: 1920px;
      margin:0 auto;
      background-image: url("@/assets/top_banner.jpg");
      background-size: 100% 100%; // 第二个参数根据第一个参数动态计算
      background-repeat: no-repeat;
      background-position: cover;
      position: relative;
      .btn_group {
        position: absolute;
        top: 70%;
        left: 8%;
      }
    }
  } 
  @media screen and (max-width: 1849px) {
    .top_banner_img {
      width: 1920px;
    }
  }
  @media screen and (min-width: 1850px) and (max-width: 1920px) {
      .top_banner_img {
          width: 100%;
        }
  }
  @media screen and (min-width: 1921px) {
    .top_banner_img {
      width: 1920px;
    }
  }
  .main_advantage{
    // height: 25vh;
    height: 400px;
    // margin-top: calc((1542 / 1920) * 100vw);
    flex-direction: column;
    .advantage_top{
      vertical-align: middle;
    }
  }
  .internect_pic_body{
    $width: 1130px;
    $height: 390px;
    background-image: url("@/assets/inter_pic.png");
    background-size: 1130px 390px; // 第二个参数根据第一个参数动态计算
    background-repeat: no-repeat;
    background-position: center center;
    width: $width;
    height: $height;
    position: relative;
    min-width: $width;
    min-height: $height;
    margin: 0 auto;
    // margin-bottom: 10vw;
  }
  .download_app_pic{
    width: 100%;
    height: 2126px;
    .download_app_pic_img{
      $width: 1920px;
      $height: 2126px;
      background-image: url("@/assets/download_app.png");
      background-size: 100% 100%; // 第二个参数根据第一个参数动态计算
      background-repeat: no-repeat;
      background-position: center center;
      width: $width;
      height: $height;
      position: relative;
      // min-height: $height;
      margin: 0 auto;
      // max-width: 1920px;
      // min-width: 1920px;
    }
  }
  .foot_banner{
    $width: 100%;
    $height: 828px;
    background-image: url("@/assets/foot.png");
    background-size: $width $height; // 第二个参数根据第一个参数动态计算
    background-repeat: no-repeat;
    background-position: center center;
    width: $width;
    height: $height;
    position: relative;
    min-height: $height;
    margin: 2vw auto;
    margin-bottom: 0;
    max-width: 1920px;
  }


  .img_btn {
    width: 160px;
    height: 50px;
    cursor: pointer;
  }

  .carousel {
    // height: 30vw;
    height: 520px;
    width: 100%;

    .carousel_body {
      $width: 1524px;
      $height: 516px;
      background-image: url("@/assets/carousel_bg.png");
      background-size: 100% $height; // 第二个参数根据第一个参数动态计算
      background-repeat: no-repeat;
      background-position: center center;
      width: $width;
      height: $height;
      position: relative;
      min-width: 1524px;

      .carousel_bg_group {
        position: absolute;
        // border: 1px solid red;
        width: 800px;
        left: 40px;
        top: -50px;
        height: 120%;
      }

      .article_r {
        height: 100%;
        width: 40%;
        position: absolute;
        right: 0;
        align-items: flex-start;
        justify-content: start;

        .article_item {
          cursor: pointer;
          margin-left: 20px;
          height: 64px;
          min-height: 64px;
          .article_text {
            margin-left: 5px;
            color: #FFF;
            font-family: PingFang SC;
            font-size: 20px;
            font-weight: 400;
          }
        }
        .fst_article_item{
          margin-top: 50px;
        }
      }
    }

    .carousel_main {
      width: 80%;
      height: 100%;
      margin: 0 auto;
    }
  }

  .advantage {
    // height: 60vh;
    width: 100%;
    // position: absolute;
    // top: 900px;

    .advantage_body {
      $width: 1524px;
      $height: 571px;
      background-image: url("@/assets/bg02.png");
      background-size: 1524px 571px; // 第二个参数根据第一个参数动态计算
      background-repeat: no-repeat;
      background-position: center center;
      width: $width;
      height: $height;
      position: relative;
      min-width: $width;
      min-height: $height;

      .avt_left {
        position: absolute;
        top: 0;
        width: 33%;
        height: 100%;

        .avt_left_obj {
          // height: 80%;
          margin-top: 20%;
          padding: 10% 10%;
          box-sizing: border-box;
        }

        $alcHeight: 135%;

        .avt_left_card {
          width: 100%;
          height: 90px;
          position: relative;
          .avt_left_card_div{
            position: absolute;
            background: #FED933;
            width: 423px;
            height: 102px;
            cursor: pointer;
          }
          .avt_left_card_disActive_div{
            position: absolute;
            background: #FFCFAD;
            // width: 390px;
            width: 423px;
            height: 93px;
            cursor: pointer;
          }
          .avt_left_card_img{
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 111;
          }
          .avt_left_card_text_obj{
            position: absolute;
            top: 0px;
            left: 15px;
            width: 423px;
            height: 102px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-around;
            cursor: pointer;

            .avt_left_card_text_item{
              z-index: 111;
              color: #FF661F;
              font-family: Alibaba PuHuiTi;
            }
            .fst_text{
              font-size: 36px;
              font-weight: 700;
            }
            .sec_text{
              font-family: PingFang SC;
              font-size: 16px;
              font-weight: 500;
            }
            .disActive_text{
              color: #FFF;
              font-family: Alibaba PuHuiTi;
              font-size: 36px;
              font-weight: 700;
            }
          }
          .avt_left_card_disActive_img{
            position: absolute;
            top: 0px;
            left: 0;
          }
        }

        .avt_left_card_active {
          width: 423px;
          height: 102px;
          flex-shrink: 0;
          fill: radial-gradient(211.36% 469.22% at 93.99% 208.7%, #FFC350 0%, #FAFF00 100%);
          filter: drop-shadow(0px 3.5566036701202393px 12.448112487792969px rgba(175, 110, 50, 0.50));
        }

        .avt_left_card_default {
          width: 390px;
          height: 93px;
          flex-shrink: 0;
          border-top: 0.889px solid #FFF;
          background: rgba(255, 255, 255, 0.50);
        }
      }

      .avt_center {
        position: absolute;
        top: 0;
        left: 33%;
        width: 50%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;
        .avt_center_container{
          width: 100%;
          height: 100%;
        }
      }

      .avt_right {
        position: absolute;
        top: 0;
        left: 83%;
        width: 17%;
        height: 100%;
        .avt_r_container{
          position: absolute;
          top: 100px;
          width: 100%;
          height: 70%;
          left: 10px;
          align-items: flex-start;
          .avt_r_title_obj{
            height: 60px;
            // width: 160px;
            width: 100%;
            color: #FFF;
            font-family: PingFang SC;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            cursor: pointer;
            justify-content: start;
          }
        }
      }
    }
  }

  .video {
    height: 620px;
    width: 100%;

    .video_main {
      width: 1089px;
      height: 613px;
      margin: 0 auto;
    }
  }
}
</style>