<template>
  <div class="home">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="banner_img">
      <img
        src="@/assets/bg03.png"
        alt="描述图片内容的文本"
        class="responsive-image"/>
    </div>
    <!-- 核心优势 -->
    <div class="l-flex main_advantage br">
      <div class="l-flex advantage_top ">
        <img
          src="@/assets/first_pic01.png"
          class="first-image"/>
        <span class="black_title">核心优势</span>
      </div>
      <div class="gray_desc ">
        Core Advantages
      </div>
    </div>
    <!-- 点击切换 -->
    <div class="tab_change br">
      <div class="br bg_image">
        sdsd
      </div>
    </div>
    <!-- 考级流程 -->
    <div class="l-flex main_advantage ">
      <div class="l-flex advantage_top ">
        <img
          src="@/assets/first_pic01.png"
          class="first-image"/>
        <span class="black_title">互联网考级流程</span>
      </div>
      <div class="gray_desc ">
        Internet Grading Process
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "HomeView",
  components: {
    // HelloWorld
  },
};
</script>

<style lang="scss" scoped>
.br {
  border: 1px solid red;
}
.l-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.first-image{
  height: 40px;
  width: 60px;
}
.home {
  // background-image: url('@/assets/bg01.jpg');
  // background-size: 100vw 880vh;
  // background-repeat: no-repeat;
  // background-position: center center;
  // // border: 1px solid red;
  // widows: 100%;
  height: 2000vh;
  .banner_img {
    .responsive-image {
      width: 100vw;
      height: auto;
    }
  }
  .main_advantage{
    margin-top: calc((20 / 1920) * 100vw);
    flex-direction: column;
    .advantage_top{
      vertical-align: middle;
    }
  }
  .tab_change{
    width: 100%;
    height: 50vh;
    display: flex;
    @extend .l-flex;
    .bg_image{
      background-image: url('@/assets/selected_bg.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
      width: 90%;
      height: 80%;
    }
  }
}

.black_title {
  color: #1c1c1c;
  font-family: Alibaba PuHuiTi;
  font-size: 40px;
  font-weight: 400;
  margin-left: 20px;
}
.gray_desc {
  color: #c6ced2;
  font-family: Alibaba PuHuiTi;
  font-size: 32px;
  font-weight: 300;
}
</style>